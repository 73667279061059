<template>
  <div class="pa-2">
    <v-card class="pa-2 mb-2" flat>
      <v-card-actions class="pa-0">
<v-select
            label="Select Type"
            v-model="SelEventtype"
            :items="EventType"
            item-text="eventtype_name"
            item-value="eventtype_id"
            dense
            outlined
            hide-details
          
          ></v-select>

        <v-select
          label="Select Company"
          v-model="SelCompany"
          :items="Company"
          item-text="company_name"
          item-value="company_id"
          dense
          outlined
          hide-details
        ></v-select>

        <v-select
        class="pa-2"
          label="Select Status"
          v-model="Selstatus"
          :items="Status"
          item-text="status_name"
          item-value="status_id"
          dense
          outlined
          hide-details
        ></v-select>

        <v-spacer></v-spacer>

        <v-btn medium color="primary" @click="btnsearch">
          <v-icon left>mdi-reload</v-icon>Load Data</v-btn
        >
      </v-card-actions>
    </v-card>
    <v-card class="pa-1 mb-2" flat>
      <v-data-table
        v-model="TDselected"
        :headers="headers"
        :items="desserts"
        :search="search"
        item-key="application_id"
        loading-text="Loading... Please wait"
        style="border-radius: 0px"
      >
        <template v-slot:top>
          <v-card flat style="position: sticky; top: 0px; z-index: 8">
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                dense
                solo-inverted
                flat
                label="Search"
                hide-details
                style="max-width: 400px"
              ></v-text-field>
            </v-card-actions>
          </v-card>
        </template>
        <!-- @click="BtnApprove(index)" -->
        <template v-slot:item.application_id="{ item }">
          <tr>
            <!-- <td style="text-align: left; width: 100px">{{ item.app_id }}</td> -->
            <td style="text-align: left; width: 65px">
              <img
                :src="$apiPath + item.picHeadShot"
                height="60px"
                width="60px"
                style="border-radius: 10px; border: 1px solid #ccc"
                type="button"
              />
            </td>
          </tr>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                class="mr-2"
                v-bind="attrs"
                v-on="on"
                @click="editItem(item.application_id, item.vendor_id)"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>Edit</span>
          </v-tooltip>
        </template>

        <template v-slot:no-data>
          <!-- <v-btn color="primary" @click="initialize">Reset</v-btn> -->
          No Record(s)
        </template>
      </v-data-table>
    </v-card>


    

    <v-dialog
      transition="dialog-top-transition"
      max-width="700"
      v-model="dialogedit"
    >
      <v-card flat>
        <v-toolbar color="primary" flat dense dark>
          Edit Application
          <v-spacer></v-spacer>
          <v-icon text @click="dialogedit = false">mdi-close</v-icon>
        </v-toolbar>
        <v-card-text class="mt-5">
          <v-row>
            <v-col cols="12" md="3">
              <img
                :src="$apiPath + displaypicpath"
                width="90px"
                style="border-radius: 10px"
              />
            </v-col>
            <v-col cols="12" md="9" class="pa-2 pt-4">
              <div
                style="font-size: 1.2rem; line-height: 1.8rem; font-weight: 600"
              >
                App ID- {{ displatAppID }}<br />
                {{ displayname }}<br />
                {{ displaycompany }}
              </div>
            </v-col>
          </v-row>
          <v-divider class="mt-2 mb-5"></v-divider>
          <v-row>

             <v-col cols="12" sm="6" md="6" >
                    <v-text-field
                      v-model="cApp.fName"
                      label="Name"
                      outlined
                      dense
                      required
                      hide-details
                    ></v-text-field>
                  </v-col>
                   <v-col cols="12" sm="6" md="6" >
                    <v-text-field
                      v-model="cApp.lName"
                      label="Last Name"
                      outlined
                      dense
                      required
                      hide-details
                    ></v-text-field>
                  </v-col>

                   <v-col cols="12" sm="6" md="6" >
                    <v-text-field
                      v-model="cApp.mName"
                      label="Unique ID"
                      outlined
                      required
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>

<v-col cols="12" md="6">
                  <v-menu
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="cApp.validTo"
                        outlined
                        dense
                        hide-details
                        label="Valid Till *"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      ref="picker"
                      v-model="cApp.validTo"
                     
                        max="2024-07-25"
                      @change="savedob"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                
<v-col cols="12" md="6">
              <v-select
                label="Select Card Type"
                v-model="cApp.card_id"
                :items="CardTypeForUpdate"
                item-text="card_name"
                item-value="card_id"
                dense
                outlined
                hide-details
              ></v-select>
            </v-col>



 <v-col cols="12" md="6">
              <v-select
                label="Select Campany"
                 v-model="cApp.vendor_id"
                 :items="Company"
          item-text="company_name"
          item-value="company_id"
                dense
                outlined
                hide-details
               
              ></v-select>
            </v-col>

            <v-col cols="12" md="6">
              <v-select
                label="Select Campany Type"
                v-model="cApp.companytype_id"
                :items="CompanyType"
                item-text="companytype_name"
                item-value="companytype_id"
                dense
                outlined
                hide-details
                @change="btnjobclear"
              ></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                label="Select Job Type"
                v-model="cApp.jobtype_id"
                :items="JobType"
                item-text="jobtype_name"
                item-value="jobtype_id"
                dense
                outlined
                hide-details
                @change="btnstandclear"
              ></v-select>
            </v-col>
            <v-col cols="12" md="12">
              <v-autocomplete
                v-model="cApp.zone"
                outlined
                :items="Stand"
                item-text="stand_name"
                item-value="stand_id"
                label="Select Stand/Zone"
                hide-details
                attach
                chips
                small-chips
                multiple
              ></v-autocomplete>
            </v-col>


<v-row>
                 <v-col cols="12" md="2" v-if="cApp.wedding==1">
                  <v-checkbox  v-model="cApp.sangeet" label="5 July"></v-checkbox>
                 
                </v-col>
                 <v-col cols="12" md="2" v-if="cApp.wedding==1">
                  <v-checkbox v-model="cApp.wedding12" label="12 July"></v-checkbox>
                 
                </v-col>
                 <v-col cols="12" md="2" v-if="cApp.wedding==1">
                  <v-checkbox v-model="cApp.wedding13" label="13 July"></v-checkbox>
                 
                </v-col>
                 <v-col cols="12" md="2" v-if="cApp.wedding==1">
                  <v-checkbox v-model="cApp.wedding14" label="14 July"></v-checkbox>
                 
                </v-col>

                <v-col cols="12" md="2" v-if="cApp.wedding==1">
                  <v-checkbox v-model="cApp.wedding15" label="15 July"></v-checkbox>
                 
                </v-col>

              </v-row>


            <!-- <v-col cols="12" md="12">
              <v-select
                label="Select Card Type"
                v-model="cApp.card_id"
                :items="CardTypeForUpdate"
                item-text="card_name"
                item-value="card_id"
                dense
                outlined
                hide-details
              ></v-select>
            </v-col> -->

<v-col cols="12" sm="6" md="12" >
                    <v-checkbox
                      v-model="emailenableFlag"
                      label="Make application as pending"
                      hide-details
                    ></v-checkbox>
                  </v-col>

          </v-row>
          <v-divider class="my-4"></v-divider>

          
          <!-- <v-row>
            <v-col cols="12" md="12">
              <v-select
                label="Select Reason"
                v-model="cApp.reason_id"
                :items="appreasonlist"
                item-text="reason_name"
                item-value="reason_id"
                dense
                outlined
                hide-details
              ></v-select>
            </v-col>
          </v-row> -->
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="primary" class="px-5" large @click="btnsave"
            ><v-icon right>mdi-content-save</v-icon> Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" shaped top :color="snackbarcolor">
      {{ snackbartext }}
      <v-btn color="indigo" text @click="snackbar = false">Close</v-btn>
    </v-snackbar>

    <v-overlay :value="overlay" style="z-index: 10">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
//import { mapMutations } from 'vuex'
//import axios from "axios";
//import moment from "moment";
//import ViewApplication from "../../components/ViewApplication.vue";
export default {
  components: {},
  data() {
    return {

 SelEventtype: "",
      EventType: [
        {
           eventtype_id: 1,
              eventtype_name: "Setup",
        },
        {
           eventtype_id: 2,
              eventtype_name: "Event",
        },


      ],

      emailenableFlag:false,
      displatAppID: "",
      displaypicpath: "",
      displaycompany: "",
      displayname: "",
      dialogedit: false,
      totalprintqu: "0",
      Printflag: "PRINT IN QUEUE",
      searchprintqueue: "",
      TDselected: [],
      singleSelect: false,
      selected: [],
      chkselected: [],
      cApp: {},
      appstatus: "none",
      cAppIndex: 0,
      date: new Date().toISOString().substr(0, 10),
      time: "",
      datebulk: new Date().toISOString().substr(0, 10),
      timebulk: "",
      isViewAppOpened: false,
      modal2: false,
      modal: false,
      overlay: false,
      modaldatebulk: false,
      modaltimebulk: false,
      menu: false,
      menu2: false,
      snackbar: false,
      snackbartext: "",
      snackbarcolor: "primary",
      EntryMode: "",
      valid: false,
      validbulk: false,
      mode: "NEW",
      newmode: true,
      sheet: false,
      sheetbulk: false,
      search: "",
      loadingstatus: false,
      cardID: "",
      Selcompanytype: "",
      event_id: "",

     Selstatus: "0",
      Status: [
//  {
//           status_id: 0,
//           status_name: "All",
//           status: "O",
//           status_named: "All",
//         },
 {
           status_id: 10,
           status_name: "Pending",
           status: "O",
           status_named: "Pending",
         },

         {
           status_id: 2,
           status_name: "Received/Submited",
           status: "O",
           status_named: "Pending",
         },
        {
          status_id: 3,
          status_name: "Approve",
          status: "O",
          status_named: "Approved",
        },
        {
          status_id: 4,
          status_name: "Amend",
          status: "O",
          status_named: "Amended",
        },
        {
          status_id: 6,
          status_name: "Reject",
          status: "O",
          status_named: "Rejected",
        },

        {
          status_id: 7,
          status_name: "Print",
          status: "O",
          status_named: "Printed",
        },
      ],

      SelstatusForUpade: "",
      StatusForUpdate: [],

      SelReasonForUpdate: "",
      ResonForUpdate: [],

      SelCardTypeForUpdate: "",

      strcheckatatus: "",

      SelCompany: "",
      Company: [],
      JobType: [],
      Stand: [],
      CardTypeForUpdate: [],
      appreasonlist: [],

      //companytype: [],
      CompanyType: [],
      postforGetdata: {
        event_id: "",
        venue_id: "",
        vendor_id: "",
      },

      postBulkUpdate: {
        fName:'',
        lName:'',
        mName:'',
        event_id: 0,
        venue_id: 0,
        vendor_id: 0,
        companytype_id: 0,
        jobtype_id: 0,
        panding:'',
       // card_id: 0,
       // reason_id: 0,
        azone: [0],
        eaid: 0,
        card_id:0,
        validTo:"",
        cType:0,
          Sangeet:0,
      Wedding12:0,
      Wedding13:0,
      Wedding14:0,
      Wedding15:0,

      },

      EditpostData:{
        application_id:0,
        vendor_id: 0,
        cType:0,
        
      },

      headers: [
        {
          text: "AppId",
          align: "left",
          value: "app_id",
          class: "blue lighten-5",
        },
         {
          text: "Photo",
          align: "left",
          value: "application_id",
          class: "blue lighten-5 w-140",
     
        },

        {
          text: "Name",
          value: "fName",
          align: "left",
          class: "blue lighten-5",
        },

        {
          text: "Company",
          value: "company_name",
          align: "left",
          class: "blue lighten-5",
        },

        {
          text: "Designation",
          value: "jobtype_name",
          align: "left",
          class: "blue lighten-5",
        },

        {
          text: "Zone",
          value: "print_text",
          align: "left",
          class: "blue lighten-5",
        },

        {
          text: "Card Name",
          value: "card_name",
          align: "left",
          class: "blue lighten-5",
        },

        {
          text: "Status",
          value: "status_named",
          align: "left",
          class: "blue lighten-5",
        },

        {
          text: "Re-Print",
          value: "reprintCount",
          align: "left",
          class: "blue lighten-5",
        },
        {
          text: "Actions",
          value: "actions",
          class: "blue lighten-5",
          sortable: false,
        },
      ],
      desserts: [],
      desserts1: [],
      Vcompany_name: [
        (v) => !!v || "Company Name is required",
        // v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],

      Vdatebulk: [
        (v) => !!v || "Date required",
        // v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],

      Vtimebulk: [
        (v) => !!v || "Time required",
        // v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],
    };
  },
  mounted() {
    this.$store.commit("setPageTitle", "Appication Edit");
    //this.$store.commit("setEventName", sessionStorage.display_event_name);
   // this.$store.commit("setVenueName", sessionStorage.display_venue_name);
    this.bindCampanytype(this.$store.state.eventInfo.EventId);
    this.bindjobtype(
      this.$store.state.eventInfo.EventId,
      this.$store.state.eventInfo.VenueId
    );
//    this.bindCartype(this.$store.state.eventInfo.EventId);
    this.bindReson("7");
    this.ReloadQuForpageload(
      this.$store.state.eventInfo.EventId,
      this.$store.state.eventInfo.VenueId
    );
    this.bindCompany(
      this.$store.state.eventInfo.EventId,
      this.$store.state.eventInfo.VenueId
    );

    
  },



  watch: {
    "cApp.companytype_id": function () {
      this.bindjobforedit(this.cApp.companytype_id);
    },
    "cApp.card_id": function () {
      this.bindCartype();
    },
  },

  methods: {

  

       savedob(date) {
      
      this.$refs.menu2.save(date);
    },
    showSnackbar: function (scolor, stext) {
      this.snackbar = true;
      this.snackbartext = stext;
      this.snackbarcolor = scolor;
    },

    bindCampanytype: async function (event_id) {
      await this.$axios
        .get("CompanyType/GetByEventId/" + event_id)
        .then((res) => {
          this.CompanyType = res.data.result;
        });
    },

    btnstandclear: function () {
      this.cApp.zone = "";
    },
    btnjobclear: function () {
      this.cApp.jobtype_id = "";
    },

    bindjobforedit: async function (cat_id) {        
      await this.$axios
        .get("JobType/GetByCompanyTypeId/" + cat_id)
        .then((res) => {
          this.JobType = res.data.result;
        });
    },

    bindjobtype: async function (EventID, VenueID) {
      this.loadingstatus = true;
     await this.$axios
        .get(
          "Stand/GetStandByEidVid/" + EventID + "/" + VenueID
        )
        .then((res) => {
          this.Stand = res.data.result;
        })
        .catch()
        .finally();
    },

    bindCartype: async function (event_id) {
      // await this.$axios
      //   .get("Cards/GetByEventId/" + event_id)
      //   .then((res) => {
      //     this.CardTypeForUpdate = res.data.result;
      //   });
      console.log(event_id);
//alert(this.SelEventtype);
//cApp.currentType
        if(this.SelEventtype==1)
          {
          this.CardTypeForUpdate=[
          {card_name:'SETUP - ARTIST',
            card_id:1
          },
          {card_name:'SETUP - EVENT',
            card_id:2
          },
          {card_name:'SETUP - RIL STAFF',
            card_id:3
          },
          {card_name:'SETUP - PLAZA',
            card_id:4
          },

      ]
}
else{
        this.CardTypeForUpdate=[
        {card_name:'RIL - FOH',
          card_id:5
        },
        {card_name:'RIL - BOH',
          card_id:6
        },
        {card_name:'RIL - ALL AREA',
          card_id:7
        },
        {card_name:'ARTIST',
          card_id:8
        },

        {card_name:'VENDOR - FOH',
          card_id:9
        },
        {card_name:'VENDOR - BOH',
          card_id:10
        },

              ]
        }
    },

    bindReson: async function (StatusID) {
      await this.$axios
        .get("Reason/GetByStatusID/" + StatusID)
        .then((res) => {
          this.appreasonlist = res.data.result;
        });
    },

    binddata: async function (application_id,vendor_id) {
     // alert("fff");
      this.EditpostData.application_id=application_id;
      this.EditpostData.vendor_id=vendor_id;
      this.EditpostData.cType=this.SelEventtype;
      //console.log(this.EditpostData);
      this.overlay = 1;
      await this.$axios
        .post(
            
            "Application/DetailsByAppType ",this.EditpostData
           
        )
        .then((res) => {
          this.cApp = res.data.result;
         // console.log(res.data.result);
          // this.cApp.dob = this.cApp.dob.slice(0, 10);
          this.displaypicpath = this.cApp.picHeadShot;
          this.displatAppID = this.cApp.app_id;
          this.displaycompany = this.cApp.company_name;
          this.displayname = this.cApp.fName + " " + this.cApp.lName;
          this.overlay = 0;
          // this.bindReson(this.cApp.status_id);
          this.bindCartype(this.$store.state.eventInfo.EventId);
        });

         
    },
//"Application/Details",this.EditpostData
    btn_reload: function () {
      this.Reload(
        this.$store.state.eventInfo.EventId,
        this.$store.state.eventInfo.VenueId
      );
    },

    btnsearch:function () {
      if (this.SelCompany.length <= 0) {
        this.showSnackbar("error", "Select Company !");
      } else {
        this.desserts = [];
        this.chkselected = [];
        this.appstatus = this.Selstatus;
        this.Reload(
          this.$store.state.eventInfo.EventId,
          this.$store.state.eventInfo.VenueId,
          this.SelCompany,
          this.Selstatus,
          "0"
        );
      }
    },

    editItem: function (application_id, vendor_id) {
      this.dialogedit = true;
      // this.displatAppID=application_id;
      //  this.displaypicpath=picpath;
      this.bindjobtype(
        this.$store.state.eventInfo.EventId,
        this.$store.state.eventInfo.VenueId
      );

      this.binddata(
      //  this.$store.state.eventInfo.EventId,
      //  this.$store.state.eventInfo.VenueId,
        application_id,
        vendor_id
        
      );

     
      
    },

    bindCompany: async function (EventID, VenueID) {
      await this.$axios
        .get(
            "vendor/GetByEventId/" +
            EventID +
            "/" +
            VenueID +
            "/" +
            this.$store.state.eventInfo.EventAdminID
        )
        .then((res) => {
          if (res.data.result.length > 0) {
            this.Company = res.data.result;
          }
        });
    },

    ReloadQuForpageload: async function (event_id, venue_id) {
     await this.$axios
        .get(
          "Print/Pending/" + event_id + "/" + venue_id
        )
        .then((res) => {
          this.desserts_load = res.data.result;

          this.desserts1 = this.desserts_load.filter(
            (task) => task.printflag == "Y"
          );
          this.totalprintqu = this.desserts1.length;
        })
        .catch()
        .finally();
    },

    ReloadQu: async function (event_id, venue_id) {
      //  this.loadingstatus = true;
      this.overlay = true;
      await this.$axios
        .get(
          "Print/Pending/" + event_id + "/" + venue_id
        )
        .then((res) => {
          this.desserts_load = res.data.result;

          this.desserts1 = this.desserts_load.filter(
            (task) => task.printflag == "Y"
          );
          this.totalprintqu = this.desserts1.length;
          this.loadingstatus = false;
          this.overlay = false;
        })
        .catch()
        .finally();
    },

    Reload: async function (event_id, venue_id, company_id, status_id, card_id) {
      //  this.loadingstatus = true;
      // alert(event_id + "/" + venue_id + "/" + company_id + "/" + status_id + "/" + card_id);
      this.overlay = true;
      await this.$axios
        .get(
            "Application/forChangeReset/" +
            event_id +
            "/" +
            venue_id +
            "/" +
            company_id +
            "/" +
            status_id +
            "/" +
            card_id + "/" + this.SelEventtype
        )
        .then((res) => {
          console.log(res.data.result);
          this.desserts = res.data.result;

          this.loadingstatus = false;
          this.overlay = false;
        })
        .catch()
        .finally();
    },


    btnsave: async function () {
      this.postBulkUpdate.event_id = this.$store.state.eventInfo.EventId;
      this.postBulkUpdate.venue_id = this.$store.state.eventInfo.VenueId;
      this.postBulkUpdate.vendor_id = this.cApp.vendor_id;
      this.postBulkUpdate.companytype_id = this.cApp.companytype_id;
      this.postBulkUpdate.jobtype_id = this.cApp.jobtype_id;
      this.postBulkUpdate.card_id = this.cApp.card_id;
     // this.postBulkUpdate.reason_id = this.cApp.reason_id;
      this.postBulkUpdate.eaid = this.$store.state.eventInfo.EventAdminID;
      this.postBulkUpdate.azone = this.cApp.zone;
      this.postBulkUpdate.fName=this.cApp.fName;
      this.postBulkUpdate.lName=this.cApp.lName;
      this.postBulkUpdate.mName=this.cApp.mName;
      this.postBulkUpdate.validTo = this.cApp.validTo;
      this.postBulkUpdate.cType = this.cApp.currentType;

      if(this.emailenableFlag==true)
      {
this.postBulkUpdate.panding='Y';
      }
      if(this.emailenableFlag==false)
      {
        this.postBulkUpdate.panding='N';
      }


       if(this.cApp.sangeet==true)
      {
        this.postBulkUpdate.Sangeet = 1;
      }
      else
      {
this.postBulkUpdate.Sangeet = 0;
      }

         if(this.cApp.wedding12==true)
      {
this.postBulkUpdate.Wedding12 = 1;
      }
      else
      {
this.postBulkUpdate.Wedding12 = 0;
      }

         if(this.cApp.wedding13==true)
      {
this.postBulkUpdate.Wedding13 = 1;
      }
      else
      {
this.postBulkUpdate.Wedding13 = 0;
      }

           if(this.cApp.wedding14==true)
      {
this.postBulkUpdate.Wedding14 = 1;
      }
      else
      {
this.postBulkUpdate.Wedding14 = 0;
      }

           if(this.cApp.wedding15==true)
      {
this.postBulkUpdate.Wedding15 = 1;
      }
      else
      {
this.postBulkUpdate.Wedding15 = 0;
      }



      //  console.log( this.cApp.application_id);
      if (this.cApp.jobtype_id == "") {
        this.showSnackbar("error", "Select Job type!");
        return;
      }
      if (this.cApp.zone == "") {
        this.showSnackbar("error", "Select Zone!");
        return;
      }
      // if (this.cApp.reason_id == "") {
      //   this.showSnackbar("error", "Select Reason!");
      //   return;
      // }

     // console.log("Hi Biki singh");
        console.log(this.postBulkUpdate);
        console.log(this.cApp.application_id);
      await this.$axios
        .put(
          "EventAdmin/applicationChangeReset/" + this.cApp.application_id,
          this.postBulkUpdate
        )
        .then((res) => {
          if (res.data.status == "Success") {
            this.showSnackbar("Success", "Data Updated!");
            this.dialogedit = false;
          }
        })
        .catch()
        .finally();
    },
  },
};
</script>

<style>
</style>